import Request, { RequestParams } from './request'
import single from '@/utils/getSingletonStore'

class NFTService extends Request {
  constructor(props: RequestParams) {
    super(props)
  }

  getNFTFullList(params: RequestPageParams) {
    return this.get<DIOX.NFTListRespone>('', { params: { module: 'nft', action: 'listnft', ...params } })
  }

  getNFTTypeList(params: RequestPageParams & { querytype: 'held' | 'managed' | 'composed'; address: string }) {
    return this.get<DIOX.NFTListRespone>('', { params: { module: 'address', action: 'nfts', ...params } })
  }

  getNFTSeriesList(params: RequestPageParams & { address: string }) {
    return this.get<DIOX.NFTSeriesListRespone>('', { params: { module: 'nft', action: 'series', ...params } })
  }

  getNFTByID(params: { id: string; querytype?: string }) {
    return this.get<DIOX.NFTIDRespone>('', { params: { module: 'nft', action: 'baseinfo', ...params } })
  }
}

export default single<NFTService>(NFTService, { baseURL: '/api' })
