export var ADDRESS_TYPE;
(function(ADDRESS_TYPE) {
    ADDRESS_TYPE["token"] = "token";
    ADDRESS_TYPE["nft"] = "nft";
    ADDRESS_TYPE["ed25519"] = "ed25519";
    ADDRESS_TYPE["dapp"] = "dapp";
})(ADDRESS_TYPE || (ADDRESS_TYPE = {}));
export var SOCIAL;
(function(SOCIAL) {
    SOCIAL["github"] = "github";
    SOCIAL["email"] = "email";
    SOCIAL["discord"] = "discord";
    SOCIAL["facebook"] = "facebook";
    SOCIAL["twitter"] = "twitter";
    SOCIAL["telegram"] = "telegram";
})(SOCIAL || (SOCIAL = {}));
export var ADDRESS_CONTRACT_PAGE = 500;
