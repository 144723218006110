import Request, { RequestParams } from './request'
import single from '@/utils/getSingletonStore'

class ShardService extends Request {
  constructor(props: RequestParams) {
    super(props)
  }

  getShardList() {
    // TODO
    return this.get<DIOX.ShardSummary[]>('', { params: { module: 'chain', action: 'shards' } })
  }

  getShardDetail(shardindex: string | number) {
    // return this.get<DIOX.ShardSummary[]>('/shards')
    return this.get<DIOX.ShardSummary[]>('', { params: { module: 'chain', action: 'shards', shardindex } })
  }
}

export default single<ShardService>(ShardService, { baseURL: '/api' })
