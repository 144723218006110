import { ADDRESS_TYPE } from '@/constants/Address'
export function makeHomeLink() {
  return '/'
}
export function makeBlockchainLink() {
  return '/blockchain'
}
export function makeAssetsLink() {
  return '/assets'
}

export function makeShardListLink() {
  return '/blockchain/shards'
}

export function makeShardDetailLink(shard: string | number) {
  return '/blockchain/shards/' + shard
}

export function makeBlockListLink() {
  return '/blockchain/blocks'
}

export function makeBlockDetailLink(hash: string) {
  return '/blockchain/blocks/' + hash
}

export function makeTxListLink() {
  return '/blockchain/transactions'
}

export function makeTxDetailLink(hash: string, relayExecIdx?: string | number) {
  return '/blockchain/transactions/' + hash + (typeof relayExecIdx !== 'undefined' ? ':' + relayExecIdx : '')
}

export function makePendingTxDetailLink(hash: string, relayExecIdx?: string | number) {
  return '/blockchain/pendingTransactions/' + hash + (typeof relayExecIdx !== 'undefined' ? ':' + relayExecIdx : '')
}

// export function makeAddressInfo(addr: string | number) {
//   return '/accounts/' + addr
// }

export function makePendingTxListLink() {
  return '/blockchain/pendingTransactions'
}

export function makeTokenList() {
  return '/assets/token'
}

export function makeNFTList() {
  return '/assets/nft'
}

export function makeDAppList() {
  return '/dapp'
}

/**
 * address token nft dapp
 * @param addr
 * @param id
 * @returns router
 */
export function makeAddressInfo(addr: string) {
  return '/assets/address/' + addr.replace(/#/g, '%23')
}
export function makeTokenInfo(symbol: string) {
  const [name, type = 'token'] = symbol.split(':')
  return `/assets/token/${name.replace(/#/g, '%23')}:${type}`
}
export function makeNFTInfo(series: string | number, id: string | number) {
  return `/assets/nft/${series}-${id}`
}
export function makeAddressTokens(addr: string) {
  return '/assets/address/' + addr.replace(/#/g, '%23') + '/tokens'
}
export function makeAddressManageTokens(addr: string) {
  return '/assets/address/' + addr.replace(/#/g, '%23') + '/managetokens'
}
export function makeAddressManageNFTs(addr: string) {
  return '/assets/address/' + addr.replace(/#/g, '%23') + '/managenfts'
}
export function makeAddressManageDApps(addr: string) {
  return '/assets/address/' + addr.replace(/#/g, '%23') + '/managedapps'
}
export function makeAddressComposedNFTs(addr: string) {
  return '/assets/address/' + addr.replace(/#/g, '%23') + '/composenfts'
}
export function makeAddressHeldNFTs(addr: string) {
  return '/assets/address/' + addr.replace(/#/g, '%23') + '/heldnfts'
}
export function makeAddressTxns(addr: string, query: { [k: string]: any } = {}) {
  const { id, ...arg } = query
  const address = id ? `${addr}-${id}` : addr
  let q = ''
  Object.keys(arg).forEach(k => {
    const unit = q.length ? '&' : '?'
    q += `${unit}${k}=${arg[k]}`
  })
  return '/assets/address/' + address.replace(/#/g, '%23') + '/txns' + q
}
export function makeAddressContracts(addr: string, id?: string) {
  const address = id ? `${addr}-${id}` : addr
  return '/assets/address/' + address.replace(/#/g, '%23') + '/contracts'
}
export function makeDAppAddress(dappSymbol: string) {
  const [symbol, type = 'dapp'] = dappSymbol.split(':')
  return `/dapp/${symbol.replace(/#/g, '%23')}:${type}`
}
export function makeDAppContracts(dappSymbol: string) {
  return '/dapp/' + dappSymbol.replace(/#/g, '%23') + '/contract'
}
export function makeDAppContract(dappSymbol: string, contractId: string) {
  return '/dapp/' + dappSymbol.replace(/#/g, '%23') + '/contract/' + contractId
}
