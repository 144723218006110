import {
  makeBlockListLink,
  makeShardListLink,
  makeTxListLink,
  makeHomeLink,
  makePendingTxListLink,
  makeTokenList,
  makeNFTList,
  makeDAppList,
  makeAddressInfo,
  makeBlockchainLink,
  makeAssetsLink,
} from '@/utils/link'

export const DEFAULT = {
  NET: process.env.NEXT_PUBLIC_DEFAULT_NET || 'BitReXe',
  TOKEN: process.env.NEXT_PUBLIC_DEFAULT_TOKEN || 'RXBTC',
  DECIMAL: process.env.NEXT_PUBLIC_DEFAULT_DECIMALS || 17,
  TOKEN_ICON: '/img/token-default.png',
  ADDRESS_ICON: '/img/address-default.png',
  DAPP_ICON: '/img/dapp-default.png',
  NFT_ICON: '/img/nft-default.png',
}
export const nets = [DEFAULT.NET]

export const navs = [
  { name: 'Overview', path: makeHomeLink() },
  {
    name: 'Blockchain',
    path: makeBlockchainLink(),
    children: [
      { name: 'Shards', path: makeShardListLink() },
      { name: 'Blocks', path: makeBlockListLink() },
      { name: 'Txns List', path: makeTxListLink() },
      { name: 'Pending Txns', path: makePendingTxListLink() },
      // { name: 'Contract', path: '/blockchain/contract' },
    ],
  },
  {
    name: 'Assets',
    path: makeAssetsLink(),
    children: [
      { name: 'Token', path: makeTokenList() },
      // { name: 'NFT', path: makeNFTList() },
    ],
  },
  { name: 'DApp', path: makeDAppList() },
  // { name: 'Accounts', path: '/accounts' },
  // { name: 'More', path: '/more' },
]
