import getAddrService from '@/api/address'
import getBlockService from '@/api/blocks'
import getShardService from '@/api/shard'
import getTxService from '@/api/transactions'

type BlockHei = {
  height: string
  shard: string | null
}

export type SearchCondition = 'Shard' | 'Block' | 'Address' | 'BlockHeight' | 'Tx' | 'Token' | 'DApp' | null
export type SearchContext = {
  searchCondition: SearchCondition
  input: string
}
export type SearchResponse<T> = {
  response: CommonResponse<T>
  context: SearchContext
}

class CombinedService {
  input: string

  constructor(input: string) {
    this.input = input.trim()
  }

  addr = getAddrService()
  shard = getShardService()
  tx = getTxService()
  block = getBlockService()
  searchCondition: SearchCondition | null = null

  regShard = /^(?:Shard)?\s?@(G|\d+)$/i
  regBlockHei = /^(\d+)(@([g|\d+]))?$/i
  regAddr = /[\w|#|-|@]+?:?\D+/i

  isAddr = () => {
    return this.regAddr.test(this.input)
  }

  getShardIndex = (): string | null => {
    let ret = null
    if (this.regShard.test(this.input)) {
      ret = this.regShard.exec(this.input)![1]
    }
    return ret
  }

  getBlockHeight = (): BlockHei | null => {
    if (this.regBlockHei.test(this.input)) {
      const [_, height, shard, shardindex] = this.regBlockHei.exec(this.input) || []
      if (!shard) {
        return { shard: null, height }
      }
      if (shardindex === 'g' || shardindex === 'G') {
        return { shard: 'g', height }
      }
      return { shard: shardindex, height }
    }
    return null
  }

  isTxHashWithGroupExidx = () => {
    return this.input.length > 52
  }

  isBlockOrTxHash = () => this.input.length === 52

  search = async (): Promise<SearchResponse<any>> => {
    // Search by address, eg. xxxxxxx:ed25519
    if (this.isAddr()) {
      const response = await this.searchAddr()
      if (response) return this.addContext(response)
    }
    // Search by shard, eg. Shard @G/1
    const shard = this.getShardIndex()
    if (shard !== null) {
      this.input = shard
      return this.searchShard().then(response => this.addContext(response))
    }
    // Search by height, eg. 100 / 100@G / 100@65535
    const block = this.getBlockHeight()
    if (block !== null) {
      return this.searchBlockByHeight(block)
    }
    // console.log(this.isTxHashWithGroupExidx(), this.isBlockOrTxHash())
    // Search by tx hash with relay group execidx, eg. xxxxxxx:101
    if (this.isTxHashWithGroupExidx()) {
      return this.searchTx(true).then(response => this.addContext(response))
    }
    // Search by tx hash or block hash
    if (this.isBlockOrTxHash()) {
      const block = await this.searchBlock(true)
      if (block.Status === 0) {
        return this.addContext(block)
      }
      const detail = await this.searchTx(true)
      return this.addContext(detail)
    }
    return Promise.reject('Invalid Input')
  }

  addContext = (res: any) => {
    return { response: res, context: { searchCondition: this.searchCondition, input: this.input } }
  }

  searchShard = () => {
    this.searchCondition = 'Shard'
    return this.shard.getShardDetail(this.input)
  }

  searchAddr = async () => {
    const [addressStr, addressType] = this.input.split(':')
    switch (addressType) {
      case 'ed25519':
        this.searchCondition = 'Address'
        break
      case 'token':
        this.searchCondition = 'Token'
        break
      case 'dapp':
        this.searchCondition = 'DApp'
        break
    }
    let result: CommonResponse<DIOX.TokenAddress | DIOX.BaseAddress>
    // try address > token > dapp
    if (!addressType) {
      try {
        const addressResult = await this.addr.getAddressDetail(addressStr + ':ed25519')
        if (addressResult.Result) {
          this.searchCondition = 'Address'
          this.input += ':ed25519'
          return addressResult
        }
        const tokenResult = await this.addr.getAddressDetail(addressStr + ':token')
        if (tokenResult.Result) {
          this.searchCondition = 'Token'
          this.input = (tokenResult.Result as DIOX.TokenAddress).Symbol + ':token'
          return tokenResult
        }
        const dappResult = await this.addr.getAddressDetail(addressStr + ':dapp')
        if (dappResult.Result) {
          this.searchCondition = 'DApp'
          this.input = dappResult.Result.Address
          return dappResult
        }
        return null
      } catch (err) {
        return null
      }
    }
    const addressResult = await this.addr.getAddressDetail(this.input)
    if (this.searchCondition === 'DApp' && addressResult.Result) {
      this.input = addressResult.Result.Address
    } else if (this.searchCondition === 'Token' && addressResult.Result) {
      this.input = (addressResult.Result as DIOX.TokenAddress).Symbol + ':token'
    }
    return addressResult
  }

  searchBlock = (hideErrorToast = false) => {
    this.searchCondition = 'Block'
    return this.block.getBlockDetail(this.input, hideErrorToast)
  }

  searchTx = (hideErrorToast = false) => {
    this.searchCondition = 'Tx'
    return this.tx.getTxDetail(this.input, hideErrorToast)
  }

  searchBlockByHeight = async (block: BlockHei) => {
    this.searchCondition = 'BlockHeight'

    let summary: CommonResponse<MasterBlockResponse> | CommonResponse<DIOX.ShardBlockResponse> | null = null
    if (block.shard === null) {
      const response = await this.block.getMasterBlockByHeight(block.height)
      summary = response
    } else {
      const response = await this.block.getShardBlocks({ height: block.height, shardIndex: block.shard })
      summary = response
    }
    return this.addContext(summary)
  }
}

export default CombinedService
