import Request, { RequestParams } from './request'
import single from '@/utils/getSingletonStore'

type ShardBlocksParam = {
  height?: number | string
  shardIndex?: number | string
  pos?: number
  limit?: number
}
class BlockService extends Request {
  constructor(props: RequestParams) {
    super(props)
  }

  getMasterBlocks(params?: RequestPageParams) {
    return this.get<MasterBlockResponse>('', { params: { module: 'block', action: 'headerhistory', ...params } })
  }

  getShardBlocks(params: ShardBlocksParam) {
    return this.get<DIOX.ShardBlockResponse>('', { params: { module: 'block', action: 'blockhistory', ...params } })
  }

  getBlockDetail(hash: string, hideErrorToast = false) {
    return this.get<DynamodbResponse<DIOX.BlockDetail>>(
      '',
      { params: { module: 'block', action: 'blockdetails', hash } },
      hideErrorToast,
    )
  }

  getMasterBlockByHeight(height: string) {
    return this.get<MasterBlockResponse>('', { params: { module: 'block', action: 'headerhistory', height } })
  }
}

export default single<BlockService>(BlockService, { baseURL: '/api' })
