import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { values } from 'mobx'
import { NumberLiteralType } from 'typescript'
import { DEFAULT } from '@/constants'

const K = 1024
const M = 1024 * K
const G = 1024 * M
const NK = 1000
const NM = 1000 * NK

type Unit = 'GB' | 'KB' | 'MB' | 'Bytes'
type SeesawProps = {
  raw: string
  startPiece?: number
  endPiece?: number
  splitStr?: string
  isHash?: boolean
  isAddress?: boolean
}
export function seesaw({ raw, startPiece = 3, endPiece = 3, splitStr = ':', isHash, isAddress }: SeesawProps) {
  if (raw.length <= startPiece + endPiece) {
    return raw
  }
  const hash = raw.split(splitStr)[0]
  const content = hash.slice(0, startPiece) + ':' + hash.slice(endPiece * -1)
  if (isHash) return `<${content}>`
  if (isAddress) return `[${content}]`
  return content
}

export function toDio(value?: string | number, withUnit?: boolean, fixed?: number): string {
  if (!value && value !== 0) return ''
  if (typeof value === 'string') {
    value = value.split(':')[0]
  }
  if (typeof fixed === 'number') {
    value = new BigNumber(value).dividedBy(10 ** Number(DEFAULT.DECIMAL)).toFixed(fixed, 1)
  } else {
    value = new BigNumber(value).dividedBy(10 ** Number(DEFAULT.DECIMAL)).toFixed()
  }
  return value.replace(/(\.[0-9]*[1-9])0*|(\.0*)/, '$1') + (withUnit ? ` ${DEFAULT.TOKEN}` : '')
}

export function toToken(props: {
  value?: string | number
  decimals?: number
  symbol?: string
  isPretty?: boolean
}): string {
  const { value, symbol, isPretty } = props
  const decimals = props?.decimals
  if (!value && value !== 0) return '--'
  let tokenValue = value
  if (typeof tokenValue === 'string') {
    tokenValue = tokenValue.split(':')[0]
  }
  if (typeof decimals === 'number') {
    tokenValue = new BigNumber(value).dividedBy(10 ** decimals).toFixed(decimals, 1)
  } else {
    tokenValue = new BigNumber(value).dividedBy(10 ** 0).toFixed()
  }

  tokenValue = tokenValue.replace(/(\.[0-9]*[1-9])0*|(\.0*)/, '$1')
  if (isPretty) {
    const [int, float] = tokenValue.split('.')
    tokenValue = prettyNumber(int) + (float ? '.' + float : '')
  }
  return tokenValue + (symbol ? ` ${symbol}` : '')
}

export function isUnset(value: any) {
  return value === '' || typeof value === 'undefined'
}

export function toShard(value: string | number, withUnit = true) {
  const unit = withUnit ? '@' : ''
  return unit + (String(value) === '65535' ? 'g' : String(value))
}

export const prettySize = (bytes: number | string, bit = 2): { value: number; unit: Unit } => {
  if (typeof bytes === 'string') {
    bytes = parseFloat(bytes)
  }

  let measure = 1
  let unit: Unit = 'Bytes'

  if (bytes > G) {
    measure = G
    unit = 'GB'
  } else if (bytes > M) {
    measure = M
    unit = 'MB'
  } else if (bytes > K) {
    measure = K
    unit = 'KB'
  }
  return {
    value: parseFloat((bytes / measure).toFixed(bit)),
    unit,
  }
}

export const prettyTxn = (value: number, bit = 2) => {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  if (value > NM) {
    return parseFloat((value / NM).toFixed(bit))
  } else if (value > NK) {
    return parseFloat((value / NK).toFixed(bit))
  }
  return value?.toLocaleString()
}

// Converts a number to a string by using the current or specified locale.
// perttyNumber(12345) will output 12,345
export const prettyNumber = (value: number | string) => {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  return value?.toLocaleString()
}

export const toMempool = (value: string) => {
  return value + 'Txn(s)'
}

export const toThroughput = (value: string) => {
  return value + 'TPS'
}

export const toShardCount = (shardOrder: string | number) => {
  return Math.pow(2, Number(shardOrder))
}

export const isGeniusHash = (value: string) => /^0+0$/.test(value)

export const toTxnUnit = (num: string | number) => {
  const bigNum = new BigNumber(num)
  const B = 10 ** 9
  const M = 10 ** 6
  const K = 10 ** 3
  if (bigNum.isGreaterThanOrEqualTo(B)) {
    return new BigNumber(num).dividedBy(B).toFixed(2) + ' B'
  }
  if (bigNum.isGreaterThanOrEqualTo(M)) {
    return bigNum.dividedBy(M).toFixed(2) + ' M'
  }
  if (bigNum.isGreaterThanOrEqualTo(K)) {
    return bigNum.dividedBy(K).toFixed(2) + ' K'
  }
  return num
}

export const toID = (id?: number | string) => {
  if (id === undefined) return ''
  return `#${id}`
}

export const toCoinAge = (age: number): string => {
  const sec = age / 10 ** 8 / (1024 * 1024)
  if (sec > 86400) {
    const day = sec / 86400
    if (day > 12) {
      const year = day / 12
      return `${year} Coin Year`
    }
    return `${day} Coin Day`
  }
  return `${sec} Coin Second`
}

// export const toDecimals = (flags?: number | string): number => {
//   if (flags === undefined) return 0
//   const n = Number(flags) >> 22
//   return parseInt(n.toString(2).substr(-6), 2)
// }

export const toUTCTime = (time: number | string | undefined) => {
  if (!time) return ''
  return dayjs(time).utc().format('YYYY/MM/DD HH:mm') + ' (UTC)'
}

export const toUTCDay = (time: number | string | undefined) => {
  if (!time) return ''
  return dayjs(time).utc().format('YYYY-MM-DD')
}

export const isSingleByte = (str: string, num: number) => {
  return str.charCodeAt(num) < 127 && ![32, 94].includes(str.charCodeAt(num))
}

export const toNFTID = (serie: string | number, id: string | number, count?: number) => {
  const bigNumberID = new BigNumber(id)
  if (count && count > 1) {
    return `#${serie} [${bigNumberID.toString(16)}] - #${count - 1 + Number(serie)} [${bigNumberID
      .plus(count - 1)
      .toString(16)}]`
  }
  return `#${serie} [${bigNumberID.toString(16)}]`
}

export const toContractID = (decimalNumber?: string | number) => {
  if (!decimalNumber) return ''
  return `#0x${Number(decimalNumber).toString(16)}`
}

// /
export const bignumberDiv = (val1: number | string, val2: number | string) => {
  return new BigNumber(val1).dividedBy(val2).toFixed()
}
// x
export const bignumberMult = (val1: number | string, val2: number | string) => {
  return new BigNumber(val1).multipliedBy(val2).toFixed()
}
// -
export const bignumberMinus = (val1: number | string, val2: number | string) => {
  return new BigNumber(val1).minus(new BigNumber(val2))
}
// +
export const bignumberPlus = (val1: number | string, val2: number | string) => {
  return new BigNumber(val1).plus(new BigNumber(val2))
}
