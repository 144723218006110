import Request from './request'
import single from '@/utils/getSingletonStore'
import { TransactionType, TXN_PK_RELAY_GROUP } from '@/constants/Transaction'

export interface TxSummary {
  Height: number
  ShardIndex: number
  ExecIndex: number
  RelayGroupIndex: number
  ShardOrder: number
  BlockTime: number
  TxnHash: string
  TxnType: string
  Initiator: string
  Target: string
  OriginalTxnHash: string
  Invocation: any
}

export interface TxDetailResponse {
  Content: DIOX.TxDetail
  ConfirmStateOverride?: string
  Hash: string
}

const request = new Request({ baseURL: '/api' })

type PendingListParams = {
  shardIndex?: string
  height?: number
  pos?: number
  limit?: number
}
type ListParmas = {
  txntype?: TransactionType
  address?: string
  addresstxntype?: string
  contract?: string
} & PendingListParams
class TransactionService {
  getList(params?: ListParmas) {
    return request.get<DIOX.TxnsResponse>('', {
      params: {
        module: 'txn',
        action: 'history',
        ...params,
      },
    })
  }
  // getTxnListByBlockHeight(params: ListParmas) {
  //   return request.get<DIOX.TxnsResponse>('', { params: filterEmptyParams({ module: 'block', action: 'txns', ...params }) })
  // }
  getTxnEmittedListByBlockHeight(params: ListParmas) {
    return request.get<DIOX.TxnsResponse>('', {
      params: { module: 'block', action: 'txn_emitted', ...params },
    })
  }
  getTxnExecutedListByBlockHeight(params: ListParmas) {
    return request.get<DIOX.TxnsResponse>('', {
      params: { module: 'block', action: 'txn_executed', ...params },
    })
  }
  getListByAddress(params?: ListParmas) {
    return request.get<DIOX.TxnsResponse>('', {
      params: { module: 'address', action: 'listtxn', ...params },
    })
  }
  getTransferList(params?: ListParmas) {
    return request.get<DIOX.TxnsResponse>('', {
      params: {
        module: 'address',
        action: 'listtxn',
        contract: 'core.coin',
        ...params,
      },
    })
  }
  getIssuedList(params?: ListParmas) {
    return request.get<DIOX.TxnsResponse>('', {
      params: {
        module: 'address',
        action: 'listtxn',
        txntype: 'UserInitiated',
        ...params,
      },
    })
  }
  getOtherList(params?: ListParmas) {
    return request.get<DIOX.TxnsResponse>('', {
      params: { module: 'address', action: 'listtxn', contract: '!core.coin', ...params },
    })
  }
  getMinedList(params?: ListParmas) {
    return request.get<DIOX.TxnsResponse>('', {
      params: {
        module: 'address',
        action: 'listtxn',
        function: 'core.coin.address.coinbase',
        ...params,
      },
    })
  }
  /**
   * get txn detail
   * @param hash txn hash
   * @param hideErrorToast stop toast
   */
  async getTxDetail(orgHash: string, hideErrorToast = false) {
    const [hash, num] = orgHash.split(':')
    const { Result } = await request.get<TxDetailResponse>(
      '',
      { params: { module: 'txn', action: 'details', hash } },
      hideErrorToast,
    )
    let detail = Result?.Content
    if (detail?.Packing?.match(TXN_PK_RELAY_GROUP) && num && detail?.Relays?.[Number(num)]) {
      detail = {
        ...detail,
        ...(detail.Relays && detail.Relays[Number(num)]),
        Hash: `${detail.Hash}:${num}`,
      }
    }
    if (detail) {
      detail.ConfirmStateOverride = Result?.ConfirmStateOverride
    }
    return detail
  }
  getPendingList(params: PendingListParams) {
    return request.get<DIOX.TxnsResponse>('', {
      params: {
        module: 'txn',
        action: 'pending',
        ...params,
      },
    })
  }
}
export type ServerList = 'getList' | 'getTransferList' | 'getOtherList' | 'getMinedList'

export default single<TransactionService>(TransactionService, { baseURL: '/api' })
