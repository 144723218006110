import Request, { RequestParams } from './request'
import single from '@/utils/getSingletonStore'
// import getTxService from '@/api/transactions'

class AddressService extends Request {
  constructor(props: RequestParams) {
    super(props)
  }

  getAddressDetail(address: string) {
    return this.get<DIOX.TokenAddress | DIOX.BaseAddress>('', {
      params: { module: 'address', action: 'detail', address },
    })
  }

  getAddressTxns(address: string, query?: RequestPageParams) {
    return this.get<DIOX.TxnsResponse>('', {
      params: {
        module: 'address',
        action: 'listtxn',
        address,
        limit: query?.limit || 20,
        pos: query?.pos || 0,
      },
    })
  }

  getAddressTokens(address: string, query?: RequestPageParams) {
    return this.get<DIOX.TokenResponse>('', {
      params: {
        module: 'address',
        action: 'tokens',
        address,
        limit: query?.limit || 20,
        pos: query?.pos || 0,
      },
    })
  }

  getAddressManagedTokens(address: string, query?: RequestPageParams) {
    return this.get<DIOX.ManageTokenResponse>('', {
      params: {
        module: 'address',
        action: 'tokens',
        address,
        limit: query?.limit || 20,
        pos: query?.pos || 0,
        querytype: 'managed',
      },
    })
  }

  getAddressManagedDApps(address: string, query?: RequestPageParams) {
    return this.get<DIOX.ManageDAppResponse>('', {
      params: {
        module: 'address',
        action: 'dapps',
        address,
        limit: query?.limit || 20,
        pos: query?.pos || 0,
        querytype: 'managed',
      },
    })
  }

  getAddressConstracts(address: string, query?: RequestPageParams) {
    return this.get<DIOX.AddressConstractRespone>('', {
      params: { module: 'address', action: 'contracts', address, limit: query?.limit || 20, pos: query?.pos || 0 },
    })
  }

  getAddressConstract(address: string, query: { height?: number; contract: string }) {
    return this.get<DIOX.AddressConstract>('', {
      params: { module: 'address', action: 'state', address, contract: query?.contract, height: query?.height },
    })
  }

  getAddressBalance(address: string) {
    return this.get<DIOX.AddressBalance>('', { params: { module: 'address', action: 'balance', address } })
  }
}

export default single<AddressService>(AddressService, { baseURL: '/api' })
