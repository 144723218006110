type Klass<T> = new (...args: any[]) => T

export default <T>(Store: Klass<T>, args?: any): (() => T) => {
  let store: T | null = null
  return function () {
    if (store === null) {
      store = new Store(args)
    }
    return store as any
  }
}
